<template>
	<div class="App font font--sans font--size-default">

		<MhDevGrid
			:doLog="false"
			:showOnHosts="[
				'localhost',
				'digiguide.local',
				'digiguide-staging.opelvillen.de'
			]"
			:show="_.get( userInfos, 'display_name' ) ? true : undefined"
			:columns="12"
			:color="'cyan'"
			:gridGap="'var(--app-gridGap)'"
			:maxWidth="'var(--app-content-maxWidth)'"
			:hPadding="'var(--app-content-hPadding)'"
			:opacity="0.35"
			:toggleKey="'g'"
			:forceVisibleOnMount="true"
		></MhDevGrid>

		<MhRouterViewWrapper
			:viewKey="$route.path"
		></MhRouterViewWrapper>

		<MhDelegateLinks
			:doLog="false"
		></MhDelegateLinks>

		<MhHeadInfos
			:doLog="$root.currentEnv.name === 'local'"
			:showDebugPanel="$root.currentEnv.name === 'xxxlocal'"
			:useCaching="true"
		></MhHeadInfos>

		<!--
		<MhDevPanel
			:doLog="false"
			:showOnHosts="[
				'localhost',
				'digiguide.local',
			]"
			XXXshow="_.get( userInfos, 'display_name' ) ? true : undefined"
		></MhDevPanel>
		-->

		<MhMailjet2 :openBtnSelector="'.openMailjetModal'"></MhMailjet2>

		<MhDevInfos
			:doLog="false"
			:showOnHosts="[
				'localhost',
				'digiguide.local',
				'digiguide-staging.opelvillen.de'
			]"
			:show="_.get( userInfos, 'display_name' ) ? true : undefined"
		></MhDevInfos>

	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import resize from 'vue-resize-directive'
	import restHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js'

	import MhRouterViewWrapper from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhRouterView/v4/MhRouterViewWrapper.vue'
	import MhHeadInfos from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhHeadInfos/v1/MhHeadInfos.vue'
	import MhDevInfos from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhDevInfos/v2/MhDevInfos.vue'
	import MhDevPanel from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhDevPanel/v1/MhDevPanel.vue'
	import MhDevGrid from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhDevGrid/v5/MhDevGrid.vue'
	import MhDelegateLinks from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhDelegateLinks/v5/MhDelegateLinks.vue'
	import MhMailjet2 from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhMailjet/v2/MhMailjet2.vue'

	export default {
		name: 'App',
		components: {
			MhRouterViewWrapper,
			MhHeadInfos,
			MhDevInfos,
			MhDevGrid,
			MhDevPanel,
			MhDelegateLinks,
			MhMailjet2,
		},
		directives: {
			resize,
		},
		mixins: [ restHandler ],
		data() {
			return {
				attachments : undefined,
				pages       : undefined,
				isReady     : false,
				userInfos   : {},
				acfOptions  : {},
			}
		},
		watch: {
			isReady: {
				handler: function( to, from ) {
					//console.log( this.$options.name, '• watch • isReady()', from, to)

					EventBus.$emit('App isReady')
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {},
		methods: {
			getLinkWithoutHostname( url ) {
				let theReturn = ''

				try {
					let _url = new URL( url )
					theReturn = _url.pathname
				}
				catch(e) {
					//
				}

				return theReturn
			},
			fetchAll( finalCallback ){
				let thingsToLoad = 2

				const runCallback = ()=>{
					--thingsToLoad

					//console.log( this.$options.name, '• fetchAll() runCallback()', thingsToLoad)

					if( !thingsToLoad && this._.isFunction( finalCallback ) ) finalCallback()
				}

				// fetch acfOptions
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/acfOptions',
					params : {},
					callback : (response) => {
						const result = response.data.result

						this.acfOptions = result

						runCallback()
					},
				})
				// fetch userInfos
				this.restHandler__fetch({
					action : 'GET',
					route : '/json/userInfos',
					params : {},
					callback : (response) => {
						this.userInfos = response.data

						runCallback()
					},
				})

				/*
				*/
				/*
				// fetch all pages
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts',
					params : {
						postType : 'page',
					},
					callback : (response) => {
						const result = response.data.result

						this.pages = result

						runCallback()
					},
				})
				*/

				// fetch all medias and transform them to acf-scheme
				/*
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/wp/v2/media',
					params : {
						per_page : 100,
					},
					callback : (response) => {
						const attachments = []
						const results = response.data

						this._.forEach( results, (o)=>{
							let post = o

							const sizesBefore =  o.media_details.sizes
							let sizesAfter = {}

							this._.forEach( sizesBefore, (v, k)=>{
								//console.log(k, v)

								sizesAfter[k] = v.source_url
								sizesAfter[k+'-width']  = v.width
								sizesAfter[k+'-height'] = v.height
							})

							post.sizes  = sizesAfter
							post.width  = o.media_details.width
							post.height = o.media_details.height
							post.caption = o.caption.rendered

							//console.log('post:', post)

							attachments.push( post )
						})

						this.attachments = attachments

						runCallback()
					},
				})
				*/
			},
			getAttachmentById( id ){
				const posts = this.attachments || []

				return this._.find( posts, { id : id })
			},
		},
		mounted(){
			this.fetchAll(()=>{
				//console.log( this.$options.name, '• fetchAll() • finalCallback()')

				this.isReady = true
			})
		}
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import "@/less/includes/animate.less";
	@import "@/less/mixins.less";
	@import "@/less/atoms.less";

	:root {
		--swatch-primary : #FAE697;
		--swatch-primary-darker : #F6CF58;

		--app-content-maxWidth : 1280px;
		--app-content-hPadding : 3rem;
		--app-gridGap : 1rem;
		--app-bgColor : lighten( black, 100 );

		--app-button-height : 45px;
		--app-button-width : 45px;
		--app-button-gap : 10px;
		--app-button-color : white;
		--app-button-transition : all 0.1s ease;
		--app-button-padding : 0 15px;
		--app-button-hoverTransform : scale(1.1);
		--app-button-activeTransform : scale(1);

		--app-gui-sidebar-bgColor : darken( #3F3A26, 5 );
		--app-gui-sidebar-color : #FAE697;
		--app-gui-sidebar-overlay-bgColor : #FAE697;
		--app-gui-sidebar-overlay-opacity : 0.7;

		--app-gui-hPadding : 2rem; //@media @mq[md] { --app-gui-hPadding : 3vw; }
		--app-gui-vPadding : 2rem; //@media @mq[md] { --app-gui-vPadding : 3vw; }
		--app-gui-fontSize-l : 32px;
		--app-gui-lineHeight-l : 1.31em;
		--app-gui-fontSize-m : 22px;
		--app-gui-lineHeight-m : 1.2em;
		--app-gui-fontSize-s : 14px;
		--app-gui-lineHeight-s : 1.35em;
		--app-gui-fontSize-xs : 10px;
		--app-gui-lineHeight-xs : 1.25em;

		--swatch-cta: darken( #FF7575, 5 );
		--swatch-cta-darken: darken( #FF7575, 10 );

		@media @mq[md] {
			--app-gui-hPadding : 4vw;
			--app-gui-vPadding : 4vw;
			--app-content-hPadding : 6vw;
			--app-gridGap : 0.5rem;
		}
		@media @mq[sm] {
			--app-gui-fontSize-l : 26px;
			--app-gui-lineHeight-l : 1.28em;
			--app-gui-fontSize-m : 18px;
			--app-button-height : 35px;
			--app-button-width : 35px;
		}
	}

	html {
		-webkit-font-smoothing: antialiased;
    	-moz-osx-font-smoothing: grayscale;
		//scroll-behavior: smooth;
		font-size: 16px;
		line-height: 1.2em;
		background-color: var(--app-bgColor);
	}
	strong, b {
		.font--bold;
	}
	button {
		cursor: pointer;
	}
	h1, h2, h3, h4, h5 {
		font-size: inherit;
		line-height: inherit;
		font-weight: inherit;
		margin: 0;
	}
	a {
		cursor: pointer;
		text-decoration: none;
	}
	[role="button"] {
		cursor: pointer;
	}
	pre {
		@color: black;

		position: relative;
		padding: 0.5em;
		width: 100%;
		min-height: 2em;

		margin-bottom: 0.5em;
		&:first-of-type { margin-top: 1em; }
		&:last-of-type { margin-bottom: 1em; }

		//color: fade(@color, 65);
		tab-size: 4;
		white-space: pre;

		outline: 1px solid fade(@color, 25);
		background-color: fade(@color, 5);
		overflow: auto;
		//display: none;

		// label
		&[name]:after {
			position: absolute;
			top: 0; right: 0;
			background-color: fade(@color, 15);
			font-size: 13px;
			line-height: 1em;
			font-family: sans-serif;
			color: fade(@color, 75);
			padding: 0.25em 0.5em;
			content: attr(name);
			font-family: inherit;
		}

		&:first-child {
			margin-top: 0em;
		}

		&[maxHeight], &[maxheight] {
			max-height: 250px;
		}
	}
	hr {
		border: none;
		border-top: 1px solid;
		margin: 0.5em 0;
		opacity: 0.25;
	}
	ul, ol {
		margin-left: 1rem;
	}
	[hidden] {
		display: none;
	}
	[data-dev-name] { // debug outlining
		[showBorders1] &.contentModule {
			outline: 1px solid fade(red, 75 );
			background-color: fade( red, 10 );
			.label( attr(data-dev-name), fade(red, 75 ) );
			&__content { background-color: fade( red, 25 ); }
		}
	}

	.view {
		&__inner {
			background-color: fade( white, 90 );
			//padding: 1rem;
		}
	}
	.hSpace--app {
		padding-left: var(--app-content-hPadding);
		padding-right: var(--app-content-hPadding);
	}
	.maxWidth--app {
		margin: 0 auto;
		max-width: var(--app-content-maxWidth);
	}
	.minHeight {
		&--viewport {
			@padding : 0em;

			min-height: calc( 100vh - @padding );
		}
	}
	.bluredBackground {
		//display: none !important;

		position: absolute;
		top: 0; left: 0px; right: 0px; bottom: 0;

		// firefox
		background-color: fade(black, 50);

		// detect feature suppoert and use the cool stuff
		// chrome + safari + edge
		// but firefox not :(
		@supports ( backdrop-filter: blur(75px) ) or ( -webkit-backdrop-filter: blur(75px) ) {
			background-color       : transparent;
			background-color       : fade(black, 50);
			-webkit-backdrop-filter: blur(65px);
			        backdrop-filter: blur(65px);
			/*
			*/
		}
	}
	.customScrollbars {
		/* width */
		&::-webkit-scrollbar {
			width: 15px;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			//box-shadow: inset 0 0 5px grey;
			border-radius: 10px;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			transition: background 0.35s ease;
			background: lighten( black, 75 );
			background: black;
			//border: 4px solid fade(black, 5);
			border: 5px solid lighten(black,25);
			border-radius: 10px;
		}
		&::-webkit-scrollbar-thumb:hover {
			background: fade( white, 95 );
		}
	}
	.richText {
		//color: green !important;
		word-wrap: break-word;

		//&

		a {
			color: var( --swatch-cta-darken );
			text-decoration: none;
			/*
			color: currentColor;
			text-decoration: underline solid var( --swatch-cta-darken );
			text-underline-offset: 0.15em;
			text-underline-weight: 0.15em;
			text-decoration-thickness: 0.15em;
			opacity: 0.5;
			*/
		}
		a:hover{
			text-decoration: underline;
			//text-underline-offset: 0.1em;
			//text-decoration: underline dotted var( --swatch-cta-darken );
		}
		ul, ol {
			margin-left: 1em;
		}
		p:not(:last-child) {
			margin-bottom: 0.5em;
		}
		em {
			//font-weight: 200;
			//font-style: normal;
		}

		@media @mq[sm] {}
	}
	.noWordWrap {
		word-wrap: initial;
	}
	.shadow {
		@color : black;
		@color : fade(black, 50px);

		&--button {
			//filter: drop-shadow( 0px 1px 4px @color );
			box-shadow:
				//0px 2px 6px -1px @color,
				0px 1px 3px 0px fade(black, 15px),
				0px 2px 10px -3px fade(black, 50),
			;
			//mix-blend-mode: multiply;
			//opacity: 1;

			&:active {
				box-shadow:
					//0px 2px 6px -1px @color,
					0px 0px 8px -2px fade(black, 50px),
					//0px 2px 10px 0px fade(black, 30),
				;
			}
			//z-index: 2;
		}
		&--coverImage {
			filter: drop-shadow( 0px 4px 5px @color );
			//mix-blend-mode: multiply;
		}
	}
	.hideScrollbars {
		-webkit-overflow-scrolling: touch; // ansonsten ist das ux absolut steif. wieder was gelernt :)
		-ms-overflow-style: none !important; // IE 10+
		scrollbar-width: none !important; // Firefox

		&::-webkit-scrollbar { width: 0px !important; }
	}
	.App {
		//background-color: lighten( black, 70 );
		min-height: 100vh;
	}
	.animatedElm { // animate elms via animate.css the animation-type is set via js in the module
		.animated;
		animation-delay: 0.5s;
		animation-duration: 0.75s;
	}

	.DgSpacer,
	.DgAudioPlayer,
	.DgVideoPlayer,
	.DgChapterHeader,
	.DgImage,
	.DgImages,
	.DgQuote,
	.DgText,
	.DgHeadline,
	.DgLogoRow {
		// grid
		&__inner {
			.grid;
			grid-gap: var(--app-gridGap);
		}
		&__content {
			display: flex;
			flex-direction: column
		}
		.DgGrid--cols-1 &__inner {
			.grid--cols-12;
		}
		.DgGrid--cols-2 &__inner {
			.grid--cols-6;
		}

		// hSpace
		.DgGrid--cols-1 & {
			padding-left: var(--app-content-hPadding);
			padding-right: var(--app-content-hPadding);
		}
		.DgGrid--cols-2 .DgColumn--index-0 & {
			padding-left: var(--app-content-hPadding);
		}
		.DgGrid--cols-2 .DgColumn--index-1 & {
			padding-right: var(--app-content-hPadding);
		}

		// maxWidth
		.DgGrid--cols-1 & {
			width: 100%;
			max-width: var(--app-content-maxWidth);
			align-self: center;
		}
		.DgGrid--cols-2 .DgColumn--index-0 & {
			width: 100%;
			max-width: calc( ( var(--app-content-maxWidth) - var(--app-gridGap) ) / 2 );
			align-self: flex-end;
		}
		.DgGrid--cols-2 .DgColumn--index-1 & {
			width: 100%;
			max-width: calc( ( var(--app-content-maxWidth) - var(--app-gridGap) ) / 2 );
			align-self: flex-start;
		}

		@media @mq[sm] {
			// grid
			.DgGrid--cols-2 &__inner {
				.grid--cols-12;
			}
			// hSpace
			.DgGrid--cols-2 .DgColumn--index-0 & {
				padding-left: var(--app-content-hPadding);
				padding-right: var(--app-content-hPadding);
			}
			.DgGrid--cols-2 .DgColumn--index-1 & {
				padding-left: var(--app-content-hPadding);
				padding-right: var(--app-content-hPadding);
			}
			// maxWidth
			.DgGrid--cols-2 .DgColumn--index-0 & {
				max-width: 100%;
			}
			.DgGrid--cols-2 .DgColumn--index-1 & {
				max-width: 100%;
			}
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
