<template>
	<MhRouterView class="HomeView view hSpace hSpace--app" :isContentReady="isContentReady">

		<div class="view__inner">

			<!--
			<br /><strong>HomeView.vue</strong><hr /><br />

			<ul>
				<li v-for="page in app.pages" :key="page.id">
					<router-link :to="app.getLinkWithoutHostname( page.link )">
						{{page.title.rendered}}
					</router-link>
				</li>
				<router-link :to="'/de/dev/'">DevView</router-link>
			</ul>

			<br/>
			-->

			<!--
			<pre data-name="pages">{{pages}}</pre>
			<pre name="attachments">{{app.attachments}}</pre>
			-->

		</div>

	</MhRouterView>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import restHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js'
	import MhRouterView from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhRouterView/v4/MhRouterView.vue'

	export default {
		name: 'HomeView',
		components: {
			MhRouterView,
		},
		mixins: [ restHandler ],
		directives: {},
		props: {},
		data(){
			return {
				isContentReady: false,
			}
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {
			fetchAll( finalCallback ){
				let thingsToLoad = 1

				const runCallback = ()=>{
					--thingsToLoad

					//console.log( this.$options.name, '• fetchAll() runCallback()', thingsToLoad)

					if( !thingsToLoad && this._.isFunction( finalCallback ) ) finalCallback()
				}

				// fetch pages
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/wp/v2/pages',
					params : {},
					callback : (response) => {
						this.pages = response.data
						this.isContentReady = true
					},
				})
			},
		},
		mounted() {
			/*
			this.fetchAll(()=>{
				//console.log( this.$options.name, '• fetchAll() • finalCallback()')

				this.isContentReady = true
			})
			*/
			this.isContentReady = true
		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.HomeView {}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
