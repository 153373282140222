import { render, staticRenderFns } from "./MhModal.vue?vue&type=template&id=f64d7384&"
import script from "./MhModal.vue?vue&type=script&lang=js&"
export * from "./MhModal.vue?vue&type=script&lang=js&"
import style0 from "./MhModal.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../GbR/10__Projekte/2021-05-12__DigiGuides/04__App2/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports