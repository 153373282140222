var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"App font font--sans font--size-default"},[_c('MhDevGrid',{attrs:{"doLog":false,"showOnHosts":[
			'localhost',
			'digiguide.local',
			'digiguide-staging.opelvillen.de'
		],"show":_vm._.get( _vm.userInfos, 'display_name' ) ? true : undefined,"columns":12,"color":'cyan',"gridGap":'var(--app-gridGap)',"maxWidth":'var(--app-content-maxWidth)',"hPadding":'var(--app-content-hPadding)',"opacity":0.35,"toggleKey":'g',"forceVisibleOnMount":true}}),_c('MhRouterViewWrapper',{attrs:{"viewKey":_vm.$route.path}}),_c('MhDelegateLinks',{attrs:{"doLog":false}}),_c('MhHeadInfos',{attrs:{"doLog":_vm.$root.currentEnv.name === 'local',"showDebugPanel":_vm.$root.currentEnv.name === 'xxxlocal',"useCaching":true}}),_c('MhMailjet2',{attrs:{"openBtnSelector":'.openMailjetModal'}}),_c('MhDevInfos',{attrs:{"doLog":false,"showOnHosts":[
			'localhost',
			'digiguide.local',
			'digiguide-staging.opelvillen.de'
		],"show":_vm._.get( _vm.userInfos, 'display_name' ) ? true : undefined}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }